<template>
  <!--begin::Basic info-->
  <div class="card">
    <!--begin::Card header-->
    <div class="card-header border-0 bg-default">
      <!--begin::Card title-->
      <div class="card-title m-0 w-100 d-flex align-items-center justify-content-between">
        <div
          class="d-flex align-items-center justify-content-start"
          @click="backMenu"
        >
          <i class="bi bi-arrow-left h3 me-4 back-menu text-white"></i>
          <h3 class="fw-bolder m-0 text-white">
            Detail Atributisasi
          </h3>
        </div>
      </div>
      <!--end::Card title-->
    </div>
    <!--end::Card header-->

    <!--begin::Content-->
    <div id="kt_account_profile_details" class="collapse show">
      <!--begin::Card body-->
      <div class="card-body border-top p-9 strip-bg">
            <div class="d-flex flex-wrap py-5">
              <div class="flex-equal me-5">
        <div class="mb-10">
          <h5 class="mb-1">Lokasi:</h5>
          <div class="d-flex flex-wrap py-5">
            <div class="flex-equal me-5">
              <table class="table fs-6 fw-semobold gs-0 gy-2 gx-2 m-0">
                <tr>
                  <td class="text-gray-400">Alamat:</td>
                  <td class="text-gray-800">{{ detail.attribution.alamat }}, {{ detail.attribution.territory_name }}, {{ detail.attribution.kecamatan_name }} - {{ detail.attribution.kabupaten_name }}</td>
                </tr>
                <tr>
                  <td class="text-gray-400">Waktu Pemasangan:</td>
                  <td class="text-gray-800">{{ detail.attribution.createdAt }}</td>
                </tr>
                <tr>
                  <td class="text-gray-400">Titik lokasi:</td>
                  <td class="text-gray-800" @click="maps({latitude: detail.attribution.latitude, longitude: detail.attribution.longitude })">
                      {{ detail.attribution.latitude }}, {{ detail.attribution.longitude }}
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
              </div>
              <div class="flex-equal text-right">
          <div class="mb-10">
            <h5 class="mb-1">Relawan:</h5>
            <div class="d-flex flex-wrap py-5">
              <div class="flex-equal me-5">
                <table class="table fs-6 fw-semobold gs-0 gy-2 gx-2 m-0">
                  <tr>
                    <td class="text-gray-400 min-w-175px w-175px">Nama Relawan:</td>
                      <td class="text-gray-800">{{ detail.created_by.name }}</td>
                  </tr>
                  <tr>
                    <td class="text-gray-400">Terdaftar:</td>
                    <td class="text-gray-800">{{ detail.created_by.createdAt }}</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
                        </div>
            </div>

              <div class="d-flex flex-wrap">
                <div class="flex-equal">
          <div class="mb-10">
            <h5 class="mb-5">Foto Depan:</h5>
            <img
                              class="img-fluid"
                              :src="detail.attribution.foto"
                              alt="foto kegiatan"
                              style="max-width: 300px;"
                            />
          </div>
                </div>
                <div class="flex-equal">
            <div class="mb-10">
              <h5 class="mb-5">Foto Belakang:</h5>
              <img
                                class="img-fluid"
                                :src="detail.attribution.foto2"
                                alt="foto kegiatan"
                                style="max-width: 300px;"
                              />
            </div>
                          </div>
                  <div class="flex-equal">
              <div class="mb-10">
                <h5 class="mb-5">Foto Kanan:</h5>
              <img
                                class="img-fluid"
                                :src="detail.attribution.foto3"
                                alt="foto kegiatan"
                                style="max-width: 300px;"
                              />
              </div>
                            </div>
                  <div class="flex-equal">
              <div class="mb-10">
                <h5 class="mb-5">Foto Kiri:</h5>
              <img
                                class="img-fluid"
                                :src="detail.attribution.foto4"
                                alt="foto kegiatan"
                                style="max-width: 300px;"
                              />
              </div>
                            </div>
              </div>
      </div>
      <!--end::Card body-->
    </div>
    <!--end::Content-->
  </div>
  <!--end::Basic info-->
</template>

<script>
import { computed, defineComponent, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "Atributisasi",
  props: ["id"],
  setup(props) {
    setCurrentPageTitle("Atributisasi");

    const store = useStore();
    const router = useRouter();

    store.dispatch("getDetailAttributeDashboard", props.id);

    const detail = computed(() => store.state.AttributeDashboardModule.detail);

    const maps = (data) => {
      window.open(
        "https://maps.google.com/?q=" + data.latitude + "," + data.longitude,
        "_blank"
      );
    };

    const backMenu = () => {
      router.push({ name: "attribute-map" });
    };

    return {
      maps,
      backMenu,
      detail,
    };
  },
});
</script>
